<script>
import ChartBarSource from '@/chart-components/components/ChartBar.vue'
export default {
  extends: ChartBarSource,
  methods: {
    /**
		 * @desc: 设置配置项
		 * @param {Array} data
		 */
		setOptions(data) {
			if (!data || !data.length) return false;
			const { attributes = {} } = this.chartData;
			this.attributes = attributes;
			const {
				showTitle = true,
				title = '',
				textColor = '',
				titleFontSize = 12,
				titleFontWeight = 400,
				titleTop = 'auto',
				titleBottom = 'auto',
				titleRight = 'auto',
				titleLeft = 'auto',
				showTooltip = true,
				showLegend = true,
				legendTextColor = '#000000',
				colorRange = [],
				barWidth = 20,
				showLine,
				// showBarLabel = true,
				barChart = false,
				axisFontSize = 12,
				axisTextColor = '#ffffff',
				xAxisText = '',
				xAxisTextFontSize = 12,
				xAxisTextColor = '#ffffff',
				yAxisText = '',
				yAxisTextFontSize = 12,
				yAxisTextColor = '#ffffff',
				left = '10%',
				right = '10%',
				top = 60,
				bottom = 60,
				legendIcon = 'circle',
				showDataZoom = false,
				dataZoomBottom = 'auto',
				dataZoomStart = 0,
				dataZoomEnd = 100,
				legendOrient = 'vertical',
				legendType = 'plain',
				usePolar = false,
				minInterval = 0
			} = attributes;
			const { 
        xData = [],
        series = [],
        // names = ''
      } = data[0];
			const xAxis = {
				type: 'category',
				data: xData,
				name: xAxisText,
				nameTextStyle: {
					color: xAxisTextColor,
					fontSize: xAxisTextFontSize,
					align: 'right'
				},
				axisTick: {
					alignWithLabel: true,
					show: false
				},
				splitLine: {
					show: false //去掉网格线
				},
				axisLabel: {
					textStyle: {
						color: axisTextColor,
						fontSize: axisFontSize
					},
					margin: 16 // 文案与坐标轴间距
				},
				axisLine: {
					show: true // 隐藏坐标轴
				}
			};
			const yAxis = [
				{
					type: 'value',
					name: yAxisText,
					nameTextStyle: {
						color: yAxisTextColor,
						fontSize: yAxisTextFontSize,
						align: 'center'
					},
					axisTick: {
						show: false // 隐藏刻度线
					},
					axisLine: {
						show: true // 隐藏坐标轴
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							color: 'rgba(194, 197, 204, 0.1)'
						}
					},
					axisLabel: {
						show: true,
						textStyle: {
							color: axisTextColor,
							fontSize: axisFontSize
						}
					}
				},
				{
					type: 'value',
					axisTick: {
						show: false // 隐藏刻度线
					},
					axisLine: {
						show: false // 隐藏坐标轴
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							color: 'rgba(194, 197, 204, 0.1)'
						}
					},
					axisLabel: {
						show: false,
						textStyle: {
							color: axisTextColor,
							fontSize: axisFontSize
						}
					}
				}
			];
			if(!isNaN(Number(minInterval))) {
				const number = Number(minInterval)
				yAxis.forEach(el => {
					el.minInterval = number
				})
			}
			this.option = {
				title: {
					show: showTitle,
					text: title,
					left: 'left',
					y: 'top',
					textStyle: {
						color: textColor,
						fontFamily: '微软雅黑',
						fontSize: titleFontSize || 12,
						fontWeight: titleFontWeight || 400,
						left: titleLeft,
						top: titleTop,
						bottom: titleBottom,
						right: titleRight
					}
				},
				tooltip: {
					show: showTooltip,
					trigger: 'axis',
					axisPointer: {
						type: 'line'
					}
				},
				legend: {
					show: showLegend,
					itemWidth: 10,
					itemHeight: 10,
					data: this.getLegendData(data[0], legendTextColor, showLine),
					icon: legendIcon,
					...this.getLegendPosition(attributes),
					type: legendType,
					orient: legendOrient,
				},
				color: colorRange || [],
				grid: {
					left,
					right,
					bottom,
					top,
					containLabel: true
				},
				dataZoom: [
					{
						type: 'slider',
						show: showDataZoom,
						backgroundColor: 'transparent',
						fillerColor: 'rgba(0, 0, 0, .1)',
						dataBackground: {
								areaStyle: {
									color: 'rgb(233, 236, 238)',
									opacity: 0.8,
									shadowColor: 'rgba(233, 236, 238, .1)'
								}
						},
						selectedDataBackground: {
								areaStyle: {
									color: 'rgb(233, 236, 238)',
									opacity: 0.8
								},
								shadowColor: 'rgba(233, 236, 238, 1)'
						},
						start: dataZoomStart || 0,
						end: dataZoomEnd || 100,
						bottom: dataZoomBottom,
					}
				],
				series: this.getSeries(series, barWidth)
			};
			// 使用极坐标
			if(usePolar) {
				this.option.polar = {
					radius: [30, '80%']
				}
				this.option.angleAxis = {
					max: Math.max(...this.option.series.map(el => Math.max(...el.data.map(ele => ele.value)))) * (4 / 3),
					startAngle: 90
				}
				this.option.radiusAxis = {
					type: 'category',
					data: yAxisText
				}
				this.option.tooltip.show = false
			} else {
				this.option.xAxis = barChart ? yAxis : xAxis
				this.option.yAxis = barChart ? xAxis : yAxis
			}
		},
		/**
		 * @desc: 获取series
		 * @param {Array} series
		 * @param {Number} barWidth
		 */
		getSeries(series, barWidth) {
			let s = [];
			if (!series) {
				return [];
			}
			const { attributes = {} } = this.chartData;
			const { XColorRange = [], distinguishColor = false, barChart = false, useGradient = false, gradientRange = [], usePolar = false } = attributes;
			for (let i = 0, len = series.length; i < len; i++) {
				const el = series[i];
				let data = el.data || [];
				if (distinguishColor || useGradient) {
					data = data.map((value, index) => {
						return {
							value,
							itemStyle: this.getItemStyle(distinguishColor, useGradient, barChart, index, i, XColorRange, gradientRange)
						}
					})
				}
        const borderRadius = 4
        data = data.map(el => {
          if(el.itemStyle) {
            el.itemStyle.borderRadius = borderRadius
          } else {
            el = {
              value: el,
              itemStyle: {
                borderRadius
              }
            }
          }
          return el
        })
				s.push({
					name: el.name,
					type: 'bar',
					barWidth,
					coordinateSystem: usePolar ? 'polar' : 'cartesian2d',
					data,
					label: {
						show: this.attributes.showBarLabel,
						position: usePolar ? 'middle' : this.attributes.barChart ? 'right' : 'top',
						formatter: '[ {c} ]',
						color: 'inherit'
					}
				});
			}
			return s;
		}
  }
}
</script>
<style lang="less" scoped>
</style>