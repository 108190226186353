<template>
  <div class="statistics-text-warp" ref="warp">
    <div class="text" :style="{ fontSize: fontSize }">
      {{ NUMBER }}
    </div>
  </div>
</template>
<script>

export default{
  name: 'Statistics',
  data() {
    return {
      fontSize: '',
      data: []
    }
  },
  computed: {
    NUMBER() {
      if(Array.isArray(this.data) && this.data.length) {
        return this.data[0]
      } else {
        return '-'
      }
    }
  },
  methods: {
    setOptions(data) {
      this.data = data
    },
    initObserve() {
      this.resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if(entry.contentRect) {
            this.handleResize(entry.contentRect)
          }
        }
      })
    },
    handleResize(contentRect) {
      const VMin = Math.min(contentRect.width, contentRect.height) / 4
      const RMin = Math.max(20, VMin)
      const RMax = Math.min(160, RMin)
      this.fontSize = Math.floor(RMax) + 'px'
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      this.initObserve()
      this.resizeObserver.observe(this.$refs.warp)
    })
  },
  beforeDestroy() {
    if(this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  }
}
</script>

<style lang="less" scoped>
  .statistics-text-warp{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .text{
      font-weight: 600;
    }
  }
</style>
