<script>
import ChartBar from '@/new_charts_components/ChartBar.vue'
import ChartLine from '@/new_charts_components/ChartLine.vue'
import ChartPie from '@/new_charts_components/ChartPie.vue'
import ChartCountup from './Statistics.vue'
import { Empty } from 'element-ui'
import { etl } from '@/saas-apis/bulletinBoard.js'
export default{
  components: {
    Empty,
    ChartBar,
    ChartLine,
    ChartPie,
    ChartCountup
  },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
      require: true
    },
    firstData: {
      type: [Object,Number],
      default() {
        return {}
      }
    }
  },
  watch: {
    'data.etlOptions': {
      handler(n, o) {
        if(n !== o) {
          // 更新类型刷新
          this.getChartData()
        }
      }
    },
    'data.key': {
      handler(n, o) {
        if(n !== o) {
          // 更新key刷新
          this.getChartData()
        }
      }
    }
  },
  data() {
    return {
      refHeight: 100,
      chartData: null,
      loading: false,
    }
  },
  methods: {
    // 获取图表数据
    getChartData() {
      if(this.data.etlOptions) {
        this.loading = true
        etl.getChartData(this.data.etlConfig.path,this.data.etlOptions).then(res => {
          if(res.data.code === 200) {
            this.chartData = res.data.data
            this.setOptions(this.chartData)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.loading = false
        })
      }
    },
    // 刷新
    refresh() {
      this.setOptions(this.chartData)
    },
    // 设置options
    setOptions(data) {
      if(this.$refs.chartRef) {
        this.$refs.chartRef.setOptions([data], true)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 盒子大小变化观察者
      this.observer = new ResizeObserver(entries => {
        for (let entry of entries) {
          this.refHeight = entry.contentRect.height
          if(this.$refs.chartRef && this.chartData) {
            this.refresh()
          }
        }
      })
      if(this.$refs.chartWrapRef) {
        this.observer.observe(this.$refs.chartWrapRef)
      }
      if(this.firstData.series) {
        this.chartData = this.firstData
        this.setOptions(this.chartData)
      } else if(!isNaN(this.firstData)) {
        this.chartData = this.firstData
        this.setOptions(this.chartData)
      } else {
        this.getChartData()
      }
    })
  },
  beforeDestroy() {
    if(this.observer) {
      // 移除所有盒子大小变化观察者
      this.observer.disconnect()
    }
  },
  render(h) {
    let componentName = this.data?.etlOptions?.type || ''
    componentName = componentName.split('')
    if(componentName.length) {
      componentName[0] = componentName[0].toUpperCase()
    }
    componentName = componentName.join('')
    const components = ['ChartBar', 'ChartLine', 'ChartPie', 'ChartCountup']
    return h('div', {
      class: 'chart-wrap',
      ref: 'chartWrapRef',
      directives: [
        {
          name: 'loading',
          value: this.loading
        }
      ]
    },[
      componentName && components.includes(`Chart${componentName}`) && this.data.chartData ? 
      h(`Chart${componentName}`, {
        props: {
          height: this.refHeight,
          chartUUID: '',
          // 图表数据
          chartData: this.data.chartData,
        },
        ref: 'chartRef'
      }) : h('Empty', { props: { description: '暂无内容！' }}, [])
    ])
  }
}
</script>
<style lang="less" scoped>
.chart-wrap{
  width: 100%;
  height: 100%;
}
</style>