<template>
  <div class="bulletin-board-container">
    <header v-if="showEdit()" class="bulletin-board-header">
      <span class="bulletin-board-name">
        <svg width="16" height="16">
          <use xlink:href="#icona-hetongwenjianfill" />
        </svg>
        <span class="bulletin-board-name-span">
          {{ sourceData?.name || '-'}}
        </span>
      </span>
      <el-button v-if="showEdit()" type="primary" size="mini" @click.stop="doEdit">
        编辑
      </el-button>
    </header>
    <section v-loading="loading" class="bulletin-board-warp" ref="warpRef">
      <GridLayout
        v-if="layout.length"
        :layout.sync="layout"
        :col-num="layoutConfig.colNum"
        :row-height="layoutConfig.rowHeight"
        :is-draggable="layoutConfig.isDraggable"
        :is-resizable="layoutConfig.isResizable"
        :is-mirrored="layoutConfig.isMirrored"
        :vertical-compact="layoutConfig.verticalCompact"
        :margin="layoutConfig.margin"
        :use-css-transforms="layoutConfig.useCssTransforms"
      >
        <GridItem 
          v-for="item in layout"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="item.i"
          :minW="3"
          :minH="3"
        >
          <div class="gird-item-warp">
            <div class="item-warp">
              <header class="item-header">
                <p class="item-title">{{ item.data.title }}</p>
                <div class="header-action">
                  <div class="action-item" @click.stop="doFullScreen(item)">
                    <svg width="16" height="16"><use xlink:href="#icondaping-quanping"></use></svg>
                  </div>
                </div>
              </header>
              <main class="item-main">
                <Chart :data="item.data"></Chart>
              </main>
            </div>
          </div>
        </GridItem>
      </GridLayout>
      <el-empty v-else description="暂无内容"></el-empty>
    </section>
    <el-dialog
      :visible.sync="dialogConfig.dialogVisible"
      :title="dialogConfig.data.title"
      :width="dialogConfig.width"
      :close-on-click-modal="dialogConfig.closeOnClickModal"
      custom-class="bulletin-board-dialog"
    >
      <header class="dialog-header" slot="title">
        <span class="title-input">{{ dialogConfig?.data?.data?.title }}</span>
      </header>
      <div v-if="dialogConfig.dialogVisible && dialogConfig.preview" class="preview">
        <Chart :data="dialogConfig.data.data" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import VueGridLayout from 'vue-grid-layout'
import Chart from './components/Chart'
import { Empty  } from 'element-ui'
import { chart } from '@/saas-apis/bulletinBoard.js'
export default{
  name: 'BulletinBoard',
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    Chart,
    'el-empty': Empty
  },
  data() {
    return {
      sourceData: {},
      loading: false,
      layout: [],
      layoutConfig: {
        colNum: 12, // 行数
        rowHeight: 30, // 小项高度
        isDraggable: false, // 可拖拽
        isResizable: false, // 可改变大小
        isMirrored: false,
        margin: [10, 10], // 间隔
        verticalCompact: true,
        useCssTransforms: true // css转变
      },
      dialogConfig: { // 弹窗配置
        dialogVisible: false,
        data: {},
        title: '',
        width: '50%',
        type: '',
        currentId: null,
        preview: false,
        closeOnClickModal: false
      },
      etlChosedData: null
    }
  },
  methods: {
    // 初始化容器的高度 => 主要是为了让区域内小项12的高度刚好占满区域
    initWarpEve() {
      const warp = this.$refs.warpRef
      const height = warp.getBoundingClientRect().height
      const cols = this.layoutConfig.colNum
      this.layoutConfig.rowHeight = ((height - 10) / cols) - 10
    },
    // 全屏
    doFullScreen(data) {
      this.dialogConfig.preview = true
      this.dialogConfig.closeOnClickModal = true
      this.dialogConfig.data = data
      this.dialogConfig.width = '70%'
      this.dialogConfig.currentId = data.data.i
      this.dialogConfig.dialogVisible = true
    },
    doEdit() {
      this.$router.push({
        name: 'BulletinBoard',
        query: {
          chart_uuid: this.sourceData.uuid, 
          id: this.sourceData.id,
          name: this.$route?.query?.name || '-'
        }
      })
    },
    getData() {
      const { chart_uuid = 0, name = '-' } = this.$route.query
      if(chart_uuid) {
        this.loading = true
        chart.details(chart_uuid).then(res => {
          if(res.data.code === 200) {
            this.sourceData = res.data.data
            this.sourceData.name = name
            if(Array.isArray(res.data.data.content)) {
              this.layout = res.data.data.content
            } else {
              this.sourceData.content = []
            }
          }
        }).catch(err => {
          console.error(err)
        }).finally(() => {
          this.loading = false
        })
      }
    },
    /* 判断编辑 */
    showEdit() {
      return this.$route.query.create_user_id && +this.$route.query.create_user_id === JSON.parse(localStorage.getItem('userInfo')).id
    }
  },
  created() {
    this.getData()
  },
  mounted() {
    this.$nextTick().then(() => {
      if(this.$refs.warpRef) {
        // 初始化容器事件
        this.initWarpEve()
      }
    })
  }
}
</script>
<style lang="less" scoped>
  .bulletin-board-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    box-sizing: border-box;
    background: #fff;
    .bulletin-board-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 11px;
      box-sizing: border-box;
      border-bottom: 1px solid #f1f1f1;
      .bulletin-board-name{
        display: flex;
        align-items: center;
        .bulletin-board-name-span{
          margin-left: 10px;
        }
      }
    }
    .bulletin-board-warp{
      flex-grow: 1;
      height: 10px;
      overflow: auto;
      :deep(.vue-grid-item){
        box-sizing: border-box;
        touch-action: none;
        .vue-resizable-handle{
          visibility: hidden;
          bottom: 5px;
          right: 5px;
          transform: scale(1.5);
        }
        &:hover{
          .vue-resizable-handle{
            visibility: visible;
          }
        }
      }
      .gird-item-warp{
        position: relative;
        width: 100%;
        height: 100%;
        padding: 5px;
        background: #fff;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid #f1f1f1;
        .item-warp{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          &:hover{
            .item-header{
              .header-action{
                visibility: visible;
              }
            }
          }
          .item-header{
            flex-shrink: 0;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .item-title{
              width: 10px;
              flex-grow: 1;
              flex-shrink: 1;
              display: inline-block;
              padding: 4px;
              border-radius: 4px;
              border: none;
              box-sizing: border-box;
              font-size: 16px;
              font-weight: 600;
              overflow: hidden;
              text-overflow: ellipsis;;
              white-space: nowrap;
              &:focus{
                border: 1px solid #409eff;
              }
            }
            .header-action{
              visibility: hidden;
              display: flex;
              align-items: center;
              .action-item{
                padding: 6px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                 &:hover{
                  cursor: pointer;
                  background: #409eff33;
                  color: #409eff;
                }
                svg + span{
                  margin-left: 8px;
                }
                & + .action-item{
                  margin-left: 10px;
                }
              }
            }
          }
          .item-main{
            flex-grow: 1;
            overflow: hidden;
          }
        }
      }
    }
  }
</style>
<style lang="less">
.action-item{
  display: flex;
  align-items: center;
  svg + span{
    margin-left: 8px;
  }
}
.action-item[type="delete"] {
  color: #ff0000;
  svg{
    path{
      fill: red !important;
    }
  }
}
.bulletin-board-container .vue-grid-item.vue-grid-placeholder {
  background: #409effaa;
}
.bulletin-board-dialog{
  border-radius: 10px;
  .dialog-header{
    .title-input{
      font-size: 18px;
      border: none;
      padding: 2px 6px;
      border-radius: 2px;
      &:focus{
        border: 1px solid #409eff;
      }
    }
  }
  .el-dialog__body{
    padding: 0 20px 30px 20px;
    .preview{
      height: 60vh;
    }
  }
}
</style>