import request from '@/apis/http/saasAuthRequest.js'
export const mapi = (data, url = 'api/mapi', method = 'post') => {
  return request({
    url: url,
    method: method,
    data
  })
}

export const etl = {
  details(path) {
    if(!path) return
    return request({
      url: `api/etl/${path}`,
      method: 'get',
    })
  },
  get(params = { page: 1, size: '20' }) {
    return request({
      url: 'api/etl',
      method: 'get',
      params
    })
  },
  getCharts(path) {
    if(!path) return
    return request({
      url: `api/etl/${path}/charts`,
      method: 'get',
    })
  },
  getChartData(path, params) {
    if(!path) return
    return request({
      url: `api/etl/${path}/chartData`,
      method: 'get',
      params
    })
  }
}

export const chart = {
  details(uuid) {
    if(!uuid) return
    return request({
      url: `api/saas/admin/chart/${uuid}`,
      method: 'get',
    })
  },
  update(uuid, data) {
    if(!uuid) return
    return request({
      url: `api/saas/admin/chart/${uuid}`,
      method: 'put',
      data
    })
  }
}